@media (max-width: 1500px) {
   .third-form button {
  
    height: 40px;
 
}
.third-form {
    margin-top: 20px;
}
.third-slide .top-h {
    margin-top: 21px;
}
}

@media (max-width: 1399.98px) { 
.third-slide .top-h {
    margin-top: 15px;
}
.main-background-image .fist-slide p {
   
    margin-top: 50px;
    
}
.main-background-image .fist-slide {
    height: 100vh !important;
    padding-top: 35px;
}
.third-slide h3 {
   
    font-size: 17px;
    line-height: 1.5;
}
.third-form input.form-control {
  
    width: 350px;
    height: 40px;
    font-size: 13px;
}
.third-form button {
  
    height: 40px;
 
}
.main-background-image .third-slide {
   height: 100vh !important;
        padding-top: 35px;
        padding: 0;
}
.third-form {
    margin-top: 0;
}
.third-slide .top-h {
    margin-top: 21px;
}
.main-background-image .slick-prev:before {
  
    width: 50px;
 
    height: 50px;
   
}
.main-background-image .slick-prev:after {
  
     width: 50px;
 
    height: 50px;
}
.main-background-image .slick-next:before {
  width: 50px;
 
    height: 50px;
}
.main-background-image .slick-next:after {
width: 50px;
 
    height: 50px;
}
.main-background-image .slick-prev:hover:after {
    top: -50px !important;
 
}
.main-background-image .slick-next:hover:after {
    top: -50px !important;
  
}
}
@media (max-width: 1299.99px) { 
.main-background-image .slick-arrow.slick-next {
    position: fixed;
    bottom: 50px !important;
    left: 0;
    z-index: 11;
    top: unset !important;
    right: 0;
    margin: auto;
}



.slick-slide img {
    display: block;
    margin-top: 17px;
}
}
@media (max-width: 1199.98px) {  
    .fist-slide > img {
    width: 400px;
}
.fist-slide h3 {
    line-height: 2;
    margin-top: 15px;
}  
.third-slide h3 {
   line-height: 1.6;
    font-size: 14px;
 
}
.third-form {
    margin-top: 15px;
}
.main-background-image .slick-arrow.slick-next {
   
    bottom: 40px !important;
   
}
.third-slide .top-h {
    margin-top: 15px;
}
.main-background-image .slick-dots li button:before {
    width: 25px;
    border-radius: 37px;
    border: 2px solid #fff;
    height: 25px;
    opacity: 1;
}
.main-background-image .slick-dots .slick-active button:before {
    color: #fff;
    width: 25px;
    border-radius: 37px;
    height: 25px;
    opacity: 1;
    padding: 2px 2px 1px 1px;
    font-size: 19px;
}
}
@media (max-width: 991.98px) { 
}

@media (max-width: 767.98px) { 
    .main-background-image .slick-dots {
        display: none!important;
    }
        .fist-slide > img {
        width: 100%;
    }
    .third-slide h3 {
      
        text-align: center;
    }
 }



@media (max-width: 575.98px) {
       .third-form input.form-control {
        width: 240px;
    }
    .main-background-image .fist-slide p {
   
    margin-left: 0;
    font-size: 18px;
    
} 
    }


