        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }
        body {
            
             font-family: "Poppins", sans-serif;
        }

.h-20 {
    font-family: Poppins;
font-size: 18px;
font-weight: 600;
line-height: 30px;


}

     .search-input input {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    background: #F5F5F5;
    width: 400px;
    color: #8391A1;
    padding-left: 38px;
    padding-right: 75px;
    height: 44px;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #E8ECF4;
}
        .search-input input::placeholder {
            color: #8391A1;
        }
        .search-input img {
            position: absolute;
            top: 14px;
            bottom: 0;
            left: 15px;
        }
        .search-input  {
        position: relative;
        }
        .search-input input:focus {
            outline: none;
        }
.filter-dropdowns a {
    padding: 5px 25px;
    border: transparent;
    background: radial-gradient(281.09% 281.09% at 50% 50%, #103D56 0%, #8BB9D6 49.52%, #4C8BB4 100%), linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%), #002395;
    border-radius: 5px;
    margin-top: 0px;
    display: block;
}
.header-inner-left {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.h-30 {
  
font-size: 25px;
font-weight: 600;
line-height: 36px;


}
.h-25 {
    font-family: Poppins;
font-size: 22px;
font-weight: 600;
line-height: 37.5px;
text-align: left;
color: #103D56;
margin: 0;
}
.header-right-links a:first-child {
    background: #F5F5F5;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #8391A1;
    text-decoration: none;
    padding: 7px 25px;
    text-align: center;
    margin-right: 10px;
    border-radius: 5px;
}
.header-right-links a:last-child {

    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    line-height: 24px;
  
    text-decoration: none;
    padding: 7px 25px;
    text-align: center;
    border-radius: 5px;
    background: radial-gradient(281.09% 281.09% at 50% 50%, #103D56 0%, #8BB9D6 49.52%, #4C8BB4 100%), linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%), #002395;
}

.inner-header {
    box-shadow: 0px 2px 20px 0px #00239526;
    background: #FFFFFF;
    padding: 15px 20px;
    border-radius: 5px;
}

.light-background {
    height: 100%;
    background: #abcee5;
}

.input-filter-box .filter-dropdowns {
    position: absolute;
    top: 5px;
    right: 5px;
}

.input-filter-box  {
    position: relative;
    
}
.header {
    padding: 15px 0;
}
.card-heading h2 {
    font-family: Poppins;
    font-size: 23px;
    font-weight: 600;
    line-height: 37.5px;
    margin: 0;
    color: #103D56;
}
.cmn-card {
    box-shadow: 0px 2px 20px 0px #00239526;
    background: #FFFFFF;
    padding: 20px 20px;
    border-radius: 5px;
}
.banner-desc img {
    width: 50px !important;
    height: 50px!important;
    object-fit: cover;
}
.banner-desc h2 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    max-width: 450px;
    line-height: 27px;
    text-align: left;
    margin: 0;
}
.banner-desc {
    display: flex;
    gap: 10px;
    position: absolute;
    padding: 13px 20px;
    bottom: 10px;
    width: fit-content;
    margin: auto;
    background-color: #fff;
    border-radius: 5px;
    left: 0;
    right: 0;
}
.banner-owl .owl-dots {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
}
.banner-owl button.owl-prev {
    position: absolute;
    top: 0;
    left: 10px;
    bottom: 0;
}
.banner-owl button.owl-next {
    position: absolute;
    top: 0;
    right: 10px;
    bottom: 0;
}

.banner-owl .owl-nav [class*='owl-']:hover {
    background: transparent!important;
}
.banner-slider::before {
    content: "";
    position: absolute;
    background: #000000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 5px;
    opacity: 0.2;
}
.banner-slider img {
    width: 100%;
    height: 370px;
    object-fit: cover;
    border-radius: 5px;
}
.home-players h2 {
    background: radial-gradient(281.09% 281.09% at 50% 50%, #103D56 0%, #8BB9D6 49.52%, #4C8BB4 100%), linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%), #002395;
    color: #fff;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    padding: 8px 15px;
    border-radius: 5px;
    margin: 0;
}
.players-inner-box {
    border-radius: 5px;
    padding: 10px 12px;
    box-shadow: 0px 2px 20px 0px #00239526;
    margin-bottom: 10px;
    display: flex;
    background-color: #fff;
    gap: 10px;
    align-items: center;
}
.players-inner-box img {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    object-fit: cover;
}
.players-inner-box h3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}
.players-inner-box h3 span {
    color: #1D161780;
}
.players-scroll-sec {
    background: #F5F5F5;
    padding: 18px;
    height: 327px;
    overflow-y: scroll;
}
.add-cards {
    position: relative;
}



.add-banner-desc img {
    width: 50px !important;
    height: 50px!important;
    object-fit: cover;
  
}
.add-banner-desc h2 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    max-width: 200px;
    line-height: 1.4;
    
    margin: 0;
}
.add-banner-desc {
    display: flex;
    gap: 10px;
    position: absolute;
    align-items: center;
    padding: 13px 20px;
    bottom: 10px;
    width: fit-content;
    margin: auto;
    background-color: #fff;
    border-radius: 5px;
    left: 0;
    right: 0;
}
.add-cards img {
    width: 100%;
    height: 240px;
    border-radius: 5px;
}
.play-card-sec h3 {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 600;
    line-height: 37.5px;
    text-align: left;
    color: #103D56;
}
.play-inner-box {
    display: flex;
    align-items: center;
    gap: 15px;
}
.play-inner-box h4 {
    margin: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
}
.play-inner-box p {
    margin: 0;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 24px;
}
.play-inner-box p span{
   color: #1D161780;

}
.badge-img img {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.badge-img {
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 5px;
    border-radius: 10px;
}
.play-inner-box > img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 10px;
}
.transfer-box {
    box-shadow: 0px 2px 20px 0px #00239526;
    border-radius: 10px;
}
.play-inner-box {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
    justify-content: space-between;
    box-shadow: 0px 2px 20px 0px #00239526;
    border-radius: 10px;
    padding: 12px;
}
.play-scroll-box {
    
    overflow-y: scroll;
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 15px;
    border-radius: 10px;
}
.play-scroll-box::-webkit-scrollbar {
   display: none;
}

.play-images img {
    width: 100%;
    height: 300px;
    border-radius: 10px;
    object-fit: cover;
}

.play-images {
    position: relative;
}
.players-value {
    background: radial-gradient(281.09% 281.09% at 50% 50%, #103D56 0%, #8BB9D6 49.52%, #4C8BB4 100%), linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%), #002395;
    padding: 10px;
    border-radius: 5px;
    width: 833px;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
}
.players-value h3 {
    font-family: Poppins;
    font-size: 30px;
    font-weight: 600;
    line-height: 45px;
    text-align: center;
    color: #fff;
    margin: 0;
}
.view-btn h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #868686;
    margin: 0;
    display: flex;
    align-items: center;
    gap: 7px;
}
.news-inner-box {
    display: flex;
    align-items: center;
    gap: 20px;
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 10px;
    border-radius: 10px;
}
.news-inner-box h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    margin: 0;
}
.news-inner-box p {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    max-width: 400px;
    margin: 0;
    padding-top: 5px;
}
.news-inner-box > img {
    width: 158px;
    height: 120px;
    object-fit: cover;
    border-radius: 10px;
}
.news-inner-box .badge-img {
    position: relative;
    top: -13px;
    right: -15px;
}
footer {
    border-radius: 5px;
    background: radial-gradient(281.09% 281.09% at 50% 50%, #103D56 0%, #8BB9D6 49.52%, #4C8BB4 100%), linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%), #002395;
    padding-top: 40px;
    
    
}
footer p {
    margin: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    text-align: center;
    color: #DFDFDF;
    margin-top: 21px;
}
.inner-menu h3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    color: #DFDFDF;
}
.inner-menu ul {
    padding: 0;
    list-style: none;
}
.inner-menu ul li a {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #DFDFDF;
    text-decoration: none;
}

.footer-menu {
    display: flex;
    gap: 100px;
    justify-content: center;
    margin: 40px 0;
}
.main-section {
  height: 100vh;
    background: linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%), #FFF;
display: flex;
align-items: center;

}

.cmn-form {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 #0000001a;
    padding: 35px 60px;
    width: 600px;
}
.pass-show {
    background: none;
    border: none;
    position: absolute;
    right: 45px;
    top: 32px;
}
.form-inner img {
    left: 14px;
    position: absolute;
    top: 17px;
}
.form-inner {
    position: relative;
}
.forgot-password {
    color: #103D56;

  font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 21px;


    text-decoration: none;
}

.cmn-red-btn {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    color: #fff;
    line-height: 24px;
    text-decoration: none;
    border: none;
    display: block;
    padding: 10px 25px;
    text-align: center;
    border-radius: 5px;
    background: radial-gradient(281.09% 281.09% at 50% 50%, #103D56 0%, #8BB9D6 49.52%, #4C8BB4 100%), linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%), #002395;
}


.h-27 {
font-family: Poppins;
font-size: 27px;
font-weight: 600;
margin: 0;
}
.sub-16 {
    font-family: Poppins;
font-size: 15px;
font-weight: 500;
line-height: 24px;
margin: 0;

}
.form-inner input {
    box-shadow: 0px 2px 20px 0px #00239526;
    background: none;
    padding: 9px 10px;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
 
}
.form-inner input::placeholder {
color: #D6D6D6;

}



.form-inner textarea {
    box-shadow: 0px 2px 20px 0px #00239526;
    background: none;
    padding: 9px 10px;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
 
}
.form-inner textarea::placeholder {
color: #D6D6D6;

}

.or-sec {
   position: relative;
    margin-top: 30px;
}
.or-sec  p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    width: 30px;
    z-index: 1111;
    margin: auto;
    background: #fff;
    position: relative;
}
.or-sec::before {
    content: "";
    position: absolute;
    background: #D6D6D6;
    width: 100%;
    height: 1px;
    top: 11px;
}
.bottom-btns button {
    box-shadow: 0px 2px 20px 0px #00239526;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 50px;
    background: #fff;
    gap: 15px;
    text-decoration: none;
    border-radius: 10px;
    border: none;
}
.bottom-btns button p {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: #262626;
    text-align: center;
    margin: 0;
}
.bottom-btns {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}
.no-acc {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}
.no-acc h4 {
    margin: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}
.no-acc a {
    color: #103D56;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-decoration: none;
    margin-left: 2px;
}
.no-acc button {
    color: #103D56;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-decoration: none;
    margin-left: 2px;
    background: none;
    border: none;
}
.text-danger {
    font-size: 14px !important;
    margin-top: 6px;
    font-weight: 400 !important;
    }
.opt-verify-fields input {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 500;
    /* padding: 0 25px; */
    margin: 0 10px;
    line-height: 21px;
    width: 100% !important;
    text-align: left;
    border-radius: 10px;
    border:2px solid #fff;
    color: #000 !important;
    height: 50px;
    background: #fff;
    border: none;
    text-align: center;
    box-shadow: 0px 2px 20px 0px #00239526;
}
.image-upload-inner.profile {
    height: 170px;
}
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }
       .opt-verify-fields input:focus {
    box-shadow: none;
    border: 2px solid #34617c;
    background: #fff;
    
    outline: none;
    box-shadow: 0px 2px 20px 0px #00239526;
}
        .opt-verify-fields input::placeholder {
        
            font-size: 13px;
            color: #8391A1 !important;
        
        }
        .opt-verify-fields {
            display: flex;
            gap: 10px;
            margin: 40px 0;
        }

.accept-condition {
    display: flex;
    gap: 10px;
    align-items: baseline;
    margin-top: 25px;
}
.accept-condition p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #28282B;
    margin: 0;
}
.accept-condition p a {
    color: #103D56;
    text-decoration: none;
    font-weight: 600;
}

.image-upload-input {
    margin: auto auto 40px;
    position: relative;
    width: 132px;
}
.image-upload-view .view-img {
    border-radius: 100px;
    bottom: 0;
    height: 130px;
    left: 0;
    object-fit: cover;
    /* position: absolute; */
    right: 0;
    top: 0;
    width: 130px;
    position: relative;
}
.image-upload-input .image-upload-camera {
    bottom: 0;
    cursor: pointer;
    position: absolute;
    right: 5px;
}
.image-upload-camera input {
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    height: 100%;
    opacity: 0;
    padding: 0;
    position: absolute;
    width: 100%;
}
.licensed-sec {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 20px 0px #00239526;
    background: none;
    padding: 9px 10px;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    border-radius: 10px;
}
.licensed-sec h3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}
.licensed-sec .form-check.form-switch {
    padding: 0;
}
.licensed-sec .form-check.form-switch input {
    width: 35px;
    height: 18px;
}
.licensed-sec .form-check.form-switch input:checked {
    background-color: #4C8BB480;
    background-position: 19px center;
        border: none;
}
.licensed-sec .form-check.form-switch input:focus {
    box-shadow: none;
}

.licensed-sec .form-switch .form-check-input {
--bs-form-switch-bg: url("../images/toggle.png");
    background-position: 3px center;
    background-size: auto;
}
.form-inner select {
    box-shadow: 0px 2px 20px 0px #00239526;
    background-color: #fff;
    padding: 9px 10px;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
}
.cmn-form-fields p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #000000;
}
.login-right-head button {
    padding: 10px 17px;
    border: transparent;
    background: radial-gradient(281.09% 281.09% at 50% 50%, #103D56 0%, #8BB9D6 49.52%, #4C8BB4 100%), linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%), #002395;
    color: #fff;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 21px;
}
.user-profile {
    display: flex;
    align-items: center;
    gap: 14px;
}
.user-profile img {
    width: 50px;
    height: 50px;
    border-radius: 40px;
    object-fit: cover;
}
.user-profile h3 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 700;
    line-height: 24px;
    margin: 0;
}
.user-profile p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}
.login-right-head {
    display: flex;
    align-items: center;
    gap: 45px;
}
.notify-sec {
    display: flex;
    gap: 25px;
}
.home-tab-filter ul {
    padding: 0;
    list-style: none;
    display: flex;
    gap: 55px;
    margin: 0;
}
.home-tab-filter {
   padding-top: 12px;
    display: flex;
    justify-content: center;
}
.home-tab-filter ul li a svg {
    margin-right: 16px;
}
.home-tab-filter ul li a {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 27px;
    color: #D5D5D5;
    text-decoration: none;
}
.header-bottom-tab a {
    display: flex;
    width: 100%;
    background: radial-gradient(281.09% 281.09% at 50% 50%, #103D56 0%, #8BB9D6 49.52%, #4C8BB4 100%), linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%), #002395;
    align-items: center;
    justify-content: center;
    height: 38px;
    border-radius: 5px;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    gap: 11px;
    line-height: 21px;
    text-align: center;
    color: #fff;
    text-decoration: none;
}
.header-bottom-tab {
    margin-top: 15px;
    margin-bottom: 20px;
}
.home-tab-filter .menu-btn.bar_active {
    background: linear-gradient(90deg, #8BB9D6 -33.16%, #4C8BB4 139.47%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}
.home-tab-filter ul li .menu-btn.bar_active svg path {
    fill: #68a0c3;
}

.play-card-sec table {
    display: block;
    width: 100%;
}

.play-card-sec table tr {
    display: block;
    width: 100%;
    background: #DCEAF3;
    padding: 8px;
}
.play-card-sec table tr th {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
}

.transfer-head {
    background: #DCEAF3;
    border-radius: 10px 10px 0 0;
    padding: 7px 15px;
}
.transfer-head h3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    margin: 0;
}
.play-data {
    display: flex;
    align-items: center;
    gap: 15px;
}
.play-data h4 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    margin: 0;
}
.play-data p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin: 0;
}
.play-data img {
    width: 38px;
    height: 38px;
    object-fit: cover;
    border-radius: 5px;
}

.transfer {
    display: flex;
    align-items: center;
    gap: 12px;
}
.transfer p {
    margin: 0;
    font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 21px;


}
.compare-players-bottom h4 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
}
.compare-box {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
    justify-content: space-between;
    box-shadow: 0px 2px 20px 0px #00239526;
    border-radius: 10px;
    padding: 12px;
}
.compare-box p {
    margin: 0!important;
    font-family: Poppins;
font-size: 14px!important;
font-weight: 500!important;
line-height: 21px!important;
color: #1D161780;


}

.compare-players-main {
    box-shadow: 0px 2px 20px 0px #00239526;
    border-radius: 10px;
    padding: 20px 30px;
    height: 477px;
    overflow-y: scroll;
}
.compare-players-main::-webkit-scrollbar {
    display: none;
}
.play-inner {
    width: 120px;
    cursor: pointer;
    height: 120px;
    box-shadow: 0px 2px 20px 0px #00239526;
    border-radius: 80px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
}
.play-inner input {
    opacity: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    cursor: pointer;
}
.play-inner .prev-img {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 76px;
}
.play-inner .up-icon {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
}
.play-box-wrap h4 {
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 14px;
}
.players-top-sec {
    display: flex;
    justify-content: space-between;
    text-align: center;
    margin-bottom: 15px;
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 15px 17px;
    border-radius: 14px;
}

.view-link {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
    color: #868686;
    text-decoration: none;
    margin: auto;
    display: table;
    margin-top: 20px;
}
.card-heading .filter {
    box-shadow: 0px 1px 10px 0px #00000033;
    padding: 7px 13px;
    border-radius: 10px;
}
.compare a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    color: #00000080;
    text-decoration: none;
    margin-right: 20px;
}
.compare {
    padding-bottom: 5px;
    border-bottom: 2px solid #D5D5D5;
}
.player-upload-div {
    background: #F8F8F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 400px;
    border: 3px solid #AFAFAF;
    border-style: dashed;
    position: relative;
    border-radius: 15px;
}
.player-upload-div p {
    font-family: Poppins;
    font-size: 18px!important;
    font-weight: 400!important;
    line-height: 30px;
    color: #ACACAC;
    text-align: center;
    margin-top: 11px!important;
}
.player-upload-div input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: pointer;
    z-index: 1;
    opacity: 0;
}
.player-upload-div .prev-img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 10px;
}
.compare-bottom p {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 600;
    line-height: 37.5px;
    margin: 0;
}
.compare-bottom {
    margin: 100px 80px 15px 80px;
}
.comp-btn a {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    display: block;
    color: #fff;
    line-height: 24px;
    margin-top: 50px ;
    text-decoration: none;
    padding: 10px 130px;
    text-align: center;
    border-radius: 5px;
    background: radial-gradient(281.09% 281.09% at 50% 50%, #103D56 0%, #8BB9D6 49.52%, #4C8BB4 100%), linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%), #002395;
}

.compare .active-compare {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    display: inline-block;
    color: #4C8BB4;
    position: relative;
}
.compare .active-compare::before {
    content: "";
    width: auto;
    height: 2px;
    background: #4C8BB4;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -7px;
}

.trophy-sec h5 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    margin: 0;
}
.trophy-sec img {
    margin-right: 10px;
}
.nav-tabs {
    border-bottom: 2px solid #D5D5D5;
}
.nav-tabs li.nav-item .nav-link.active {
    border: none !important;
    border-bottom: 2px solid #4C8BB4 !important;
    color: #4C8BB4;

}
.nav-tabs li.nav-item button {
    border: none;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    color: #00000080;
}
.light-blue-btn {
    background: #4C8BB4;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #fff;
    padding: 8px 25px;
    border: none;
    border-radius: 10px;
    text-decoration: none;
}
.light-blue-btn-big {
    background: linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%);
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
    color: #fff;
    border: none;
    min-width: 370px;
    height: 46px;
    border-radius: 5px;
    margin-bottom: 21px;
}
.messages-total h5 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 15px;
}
.message-box {
    display: flex;
    align-items: center;
    gap: 15px;
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 8px 10px;
    border-radius: 15px;
}
.message-box h6 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
   
    margin: 0;
}
.message-box p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
    color: #a59f9f;
}
.message-box span {
    background: #4C8BB433;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 600;
    color: #4C8BB4;
    line-height: 15px;
    padding: 6px 10px;
    border-radius: 25px;
}
.message-box h3 {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    color: #a59f9f;
    margin: 0;
}

.message-left .heading {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-top: 15px;
}
.messages-total {
    height: 500px;
    overflow-y: scroll;
    padding: 3px;
}
.messages-total::-webkit-scrollbar {
    display: none;
}
.message-right {
    background: #F5F5F5;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 15px;
}
.user-top-info {
    box-shadow: 0px 2px 20px 0px #4C8BB426;
    background: #fff;
    border-radius: 10px;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.user-top-inner {
    display: flex;
    align-items: center;
    gap: 11px;
}
.user-top-inner img {
    width: 50px;
    height: 50px;
}
.user-top-inner h3 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    margin: 0;
}
.user-top-inner p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    margin: 0;
}

.user-top-info .dropdown button {
    background: none;
    border: none;
    padding: 0;
}
.user-top-info .btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active {
background-color: transparent!important;
}
.user-top-info .dropdown .dropdown-item {
    font-size: 13px;
}
.left-user {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 10px;
}
.left-user img {
    border-radius: 41px;
    width: 50px;
    height: 50px;
    object-fit: cover;
}
.left-user h4 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    background: #fff;
    padding: 15px 20px;
    border-radius: 10px;
}
.left-user p {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 10px;
    color: #797C7B80;
    margin: 0;
    text-align: end;
}
.message-box-sec {
    height: 45vh;
    display: flex;
    overflow-y: scroll;
    flex-direction: column;
    margin-bottom: 16px;
    justify-content: end;
}
.message-box-sec::-webkit-scrollbar {
    display: none;
}
.message-send {
    display: flex;
    gap: 15px;
}
.message-send input.form-control {
    box-shadow: 0px 2px 20px 0px #4C8BB426;
    padding: 11px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21.82px;
    border: none;
}
.message-send button {
    box-shadow: 0px 2px 20px 0px #4C8BB426;
    background: #fff;
    border: none;
    padding: 10px 12px;
    border-radius: 7px;
}

.cmn-modal button.btn.btn-primary {
    background: linear-gradient(90deg, #8BB9D6 -33.16%, #4C8BB4 139.47%);
    border: none;
    font-size: 14px;
    padding: 10px 84px;
}
.cmn-modal .modal-title {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 600;
    line-height: 37.5px;
    color: #103D56;
}
.cmn-modal button.btn-close {
    box-shadow: 0px 0px 10px 0px #4C8BB44D;
    border-radius: 36px;
    width: 23px;
    height: 23px;
}
.cmn-modal .modal-content {
    padding: 15px;
}
.small-cmn-shadow {
    display: flex;
    gap: 10px;
    align-items: center;
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 10px;
    border-radius: 10px;
    height: 50px;
}
.small-cmn-shadow h3 {
    margin: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
.small-cmn-shadow img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 57px;
}
.small-cmn-shadow h4 {
    margin: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}
.small-cmn-shadow input {
    width: 167px;
    border: none;
    background: none;
    font-size: 13px;
}
.small-cmn-shadow button {
    background: none;
    border: none;
}
.choose-agent h3 {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
}
.choose-agent p {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    margin: 0;
}


.calendor-box .react-calendar {
    border: 2px solid #4C8BB4;
    border-radius: 10px;
}
.meetings-main h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}
.meeting-box {
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 15px;
}
.meeting-box h5 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
}
.meeting-box p {
    font-size: 11px;
    font-weight: 500;
    color: #00000080;
    line-height: 1.3;
    margin: 0;
}


.schedule-btn button {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: center;
        background: linear-gradient(90deg, #8BB9D6 -33.16%, #4C8BB4 139.47%);
}
.schedule-btn button {
    font-family: Poppins;
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    background: linear-gradient(90deg, #8BB9D6 -33.16%, #4C8BB4 139.47%);
    color: #fff;
    border: none;
    padding: 10px 120px;
    border-radius: 10px;
}

.profile-btns a img {
    width: 32px;
}
.social-media {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}
.profile-card a {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 12px 20px;
    border-radius: 10px;
    text-decoration: none;
}
.profile-card a h3 {
    margin: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #1D1617;
}

.profile-card button {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 12px 20px;
    border-radius: 10px;
    text-decoration: none;
    width: 100%;
    border: none;
    background: #fff;
    align-items: center;
}
.profile-card button h3 {
    margin: 0;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #1D1617;
}
.profile-user .user-img {
    width: 100%;
    height: 190px;
    object-fit: cover;
    border-radius: 15px;
}

.message-box img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 10px;
}
.player-box {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 12px 13px;
    border-radius: 10px;
}
.play-outer {
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 13px;
    border-radius: 10px;
}
.player-box img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
}
.player-box h3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
    margin: 0;
}
.player-box h5 {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    color: #1D161780;
    margin-top: 3px;
}
.player-box p {
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
    margin: 0;
    color: #1D161780;
}
.player-box p span{
    font-family: Poppins;
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
    margin: 0;
  
    color: #1D1617;

}
.player-box h3 span {
    color: #1D161780;
    font-weight: 500;
    font-size: 14px;
}


.subscription h2 {
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
}


.subscription h2 span{
font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 21px;


}
.subscription p.text-center {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
    margin-top: 15px;
}
.subscription ul li {
    display: flex;
    gap: 11px;
}
.subscription ul {
    padding: 0;
}
.subscription ul h3 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    color: #000;
}
.subscription h4 {
    color: #757575;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24.04px;
    margin: 0;
}
.subscription ul li div {
    width: fit-content;
}
.subscription {
    padding: 0 40px;
}

.common-outer {
    box-shadow: 0px 2px 20px 0px #00239526;
    background: #FFFFFF;
    padding: 20px 20px;
    border-radius: 5px;
}
.image-upload-main h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}
.image-upload-inner {
    box-shadow: 0px 2px 20px 0px #00239526;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 10px;
    margin-top: 15px;
    position: relative;
    cursor: pointer;
}
.image-upload-placeholder h4 {
    margin-top: 13px;
    font-family: Poppins;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.image-upload-inner input[type="file"] {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}
.image-upload-inner .prev-img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.image-upload-main {
    position: relative;
}
.image-upload-inner button {
    background: none;
    border: none;
    position: absolute;
    right: 15px;
    z-index: 111;
    top: 11px;
}
.image-upload-inner.small {
    height: 215px;
}
.image-upload-inner.small .image-upload-placeholder img {
    width: 49px;
}
.video-link h3 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}
.video-link input {
    border: 1px solid #c3bbbb;
    padding: 10px 12px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #DADADA;
}
.video-link small {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
}


.cmn-light-btn button {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    background: linear-gradient(90deg, #8BB9D6 -33.16%, #4C8BB4 139.47%);
    color: #fff;
    border: none;
    padding: 7px 150px;
    border-radius: 7px;
}


.cmn-light-btn a {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    text-align: center;
    background: linear-gradient(90deg, #8BB9D6 -33.16%, #4C8BB4 139.47%);
    color: #fff;
    border: none;
    padding: 7px 150px;
    border-radius: 7px;
    text-decoration: none;
}
.accord-tabs .nav.nav-tabs {
    display: flex;
    justify-content: space-between;
    border: none;
    gap: 20px;
    margin-bottom: 0!important;
}
.accord-tabs li.nav-item .nav-link.active  {
    border: none!important;
    color: #000;
    border-radius: 10px;
}
.accord-tabs .nav.nav-tabs li {
   
    width: calc(96% / 3);
    border-radius: 10px;
}
.accord-tabs li.nav-item button {
   box-shadow: 0px 2px 20px 0px #00239526;
    width: 100%;
    border-radius: 10px;
}
.accord-tabs .tab-content {
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 10px;
    border-radius: 0 0 10px 10px;
}

.accord-tabs .nav.nav-tabs li .nav-link.active {

}


.play-dropdown button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 2px 20px 0px #00239526;
    background: #fff;
    border: none;
    color: #000;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    padding: 11px 20px;
    line-height: 21px;
    background-image: url("../images/arrowdown.svg");
    background-repeat: no-repeat;
    background-position: center right 20px;
}
.play-dropdown button:hover {
    background-color: #fff;
    color: #000;
}
.play-dropdown .dropdown-toggle::after {
    display: none;
}
.play-dropdown .dropdown-menu.show {
    width: 100%;
    box-shadow: 0px 2px 20px 0px #00239526;
    border: none;
}
.play-dropdown .dropdown-menu.show a.dropdown-item {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
}
.play-dropdown .btn.show {
    color: #000;
}
.select-file {
    background: #E9E9E9;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    position: relative;
    border-radius: 5px;
}
.select-file p  {
   font-family: Poppins;
font-size: 14px;
font-weight: 400;
line-height: 21px;
color: #000000;

margin: 0;
}
.select-file input[type="file"] {
position: absolute;
left: 0;
right: 0;
top: 0;
bottom: 0;
width: 100%;
height: 100%;
opacity: 0;
cursor: pointer;
}
.cmn-form-fields input {
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 10px 10px;
    border: none;
    font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 21px;


}

.common-shadow {
    box-shadow: 0px 2px 20px 0px #00239526;
    background: #FFFFFF;
    padding: 20px 20px;
    border-radius: 5px;
}
.common-shadow h3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
}



.num-inputs {
    display: flex;
    gap: 75px;
}
.num-inputs input {
    border: 1px solid #000;
    box-shadow: none;
   

}
.phone-select {
    display: flex;
    align-items: center;
}
.num-inputs input::placeholder {
 color: #DADADA;

}
.image-upload-placeholder img.inner-view {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 10px;
    object-fit: cover;
}
.num-inputs input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    appearance: auto;
}
.player-profile-main {
    height: 280px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
}
.player-left-info {
    display: flex;
    align-items: end;
    gap: 15px;
}
.player-left-info .play-img {
    width: 230px;
    height: 235px;
    object-fit: cover;
}
.player-left-info h2 {
    font-family: Lato;
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    color: #FFFFFF;
}
.player-left-info p {
    font-family: Lato;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: #fff;
    margin-right: 10px;
}
.player-left-info button {
    background: none;
    border: none;
}
.socio-icons {
    display: flex;
    gap: 15px;
    margin-top: 10px;
    margin-bottom: 35px;
}
.socio-icons a {
    background: #FFFFFF;
    border-radius: 20px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.socio-icons a img {
    width: 20px;
    height: 15px;
}
.player-profile-main::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background: #2626266e;
    z-index: -1;
}
.play-edit a {
    color: #fff;
    text-decoration: none;
    display: flex;
    gap: 10px;
    align-items: center;
}
.play-info-box {
    display: flex;
    gap: 10px;
    border: 1px solid #fff;
    width: 417px;
    border-radius: 10px;
    padding: 14px;
    justify-content: space-between;
}
.play-info-box h3 {
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #FFF;
}
.play-info-box p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #fff;
    margin: 0;
}
.player-right-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 25px;
    align-items: end;
}


.search-cmn-box h2 {
    font-family: Poppins;
    font-size: 15px;
    font-weight: 600;
    line-height: 24px;
}
.search-inner-box ul {
    padding: 0;
    list-style: none;
    margin: 0;
}
.search-inner-box {
    border: 2px solid #4C8BB4;
    box-shadow: 0px 2px 20px 0px #00239526;
    padding: 8px;
    border-radius: 10px;
}
.search-inner-box ul li {
    display: flex;
    box-shadow: 0px 2px 20px 0px #00239526;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
    border-radius: 8px;
    margin-bottom: 12px;
}
.search-inner-box ul li h4 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
}
.search-inner-box ul li p {
    font-family: Poppins;
    font-size: 11px;
    font-weight: 500;
    color: #140f1080;
    margin: 0;
}

.play-inner-box.search-result .badge-img img {
    width: 20px !important;
    height: 20px !important;
}
.play-inner-box.search-result .small-images {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    margin: -6px;
}
.serviceslist_accord .accordion-header button p {
    margin-left: 20px;
}

.players-value.inner-tab {
    width: 90%;
}
.players-value.inner-tab h3 {
    font-size: 13px;
    line-height: 1.2;
}
.search-inner-box .in-heading {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
}





.play-search-box {
    display: flex;
    gap: 10px;
    border: 1px solid #fff;
    width: 100%;
    border-radius: 10px;

    justify-content: space-between;
}
.play-search-box h3 {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #000;
}
.play-search-box p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 400;
    line-height: 21px;
    text-align: center;
    color: #1D161780;
    margin: 0;

}

.flag-img {
    position: absolute;
    width: 58px;
    /* box-shadow: 0px 2px 20px 0px #00239526; */
    /* background-color: #fff; */
    height: 46px;
    object-fit: contain;
    left: 0 !important;
    padding: 5px;
    border-radius: 8px 0 0 8px;
    top: 30px !important;
}
.city-select select {
    padding-left: 65px;
}
.user-profile .dropdown button {
    padding: 0;
    background: none;
}
.user-profile .dropdown button h3 {
    color: #000;
    text-align: left;
}
.user-profile .dropdown button p {
    color: #171717;

}
.user-profile .dropdown .dropdown-item {
    font-size: 15px;
}
.user-profile .dropdown button::after {
    background-image: url("../images/arrowdown.svg");
    width: 18px;
    height: 15px;
    background-size: contain;
    background-repeat: no-repeat;
}
.agent-socio-icons {
    display: flex;
    gap: 20px;
    margin-right: 40px;
}

.form-control:focus {
    box-shadow: 0px 2px 20px 0px #00239526;
}

.main-section.height-set {
    height: 100%;
    padding: 120px 0;
}


.form-flag .css-1dimb5e-singleValue {
    background-color: #fff;
    border: none;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 400;
}
.form-flag .css-13cymwt-control {
    box-shadow: 0px 2px 20px 0px #00239526;
    background-color: #fff;
    border : none;
} 
.form-flag .css-t3ipsp-control {
    border-color: transparent!important;
    box-shadow: 0px 2px 20px 0px #00239526;
}


.gradient-sec {
    background: linear-gradient(91deg, #2d5fe5, #ef117e);
        padding: 95px 0;
}
.bottom-form-sec {
    padding: 30px 0;
    margin: 0 auto;
    max-width: 690px;
}
.bottom-form-sec h2 {
    margin: 0 auto 55px;
    max-width: 600px;
    color: #fff;
    font-size: 25px;
    text-align: center;
}

.form-detail {
    background: #fff;
    border: 1px solid #0a4949;
    border-radius: 6px;
    padding: 30px;
}

.form-detail input.form-control {
    background: #fff;
    border-bottom: 0.5px solid #000 !important;
    border: 0;
    border-radius: 0;
    font-size: 13px;
    color: #000;
    line-height: 22.857px;
    padding: 8px 0;
}
.form-detail input.form-control::placeholder {
    color: #000;
}
.form-detail h3 {
    font-size: 18px;
    line-height: 25px;
}

.form-detail input.form-control:focus {
    box-shadow: none;
 }

 .form-detail input.form-control {
    background: #fff;
    border-bottom: 0.5px solid #000 !important;
    border: 0;
    border-radius: 0;
    font-size: 13px;
    color: #000;
    line-height: 22.857px;
    padding: 8px 0;
}
.form-detail input.form-control::placeholder {
    color: #000;
}
.form-detail h3 {
    font-size: 18px;
    line-height: 25px;
}

.formik-errors {
    color: red;
    font-size: 12px;
}

.register-btn button {
    background: #2D5FE5;
      border: none;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: -.3px;
      line-height: 20px;
      padding: 15px;
      width: 300px;
  }

  .terms_check {
    padding: 20px 120px;
}
.terms_check span {
    color: #000;
    font-size: 13px;
}
.terms_check a {
    color: #000;
}
.terms_check .form-check input {
    border: 1px solid #aeb5b5;
}

button.lg-btn-disabled {
    cursor: not-allowed;
    background: #aeb5b5;
}

.main-background-image {
    background-image: url("../images/HOMEBANNER.png");
    width: 100%;
        z-index: 1;
    position: relative;
    background-size: cover;
     transition: background-position 0.1s;  
}
.main-background-image .fist-slide {
    height: 100vh !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.main-background-image .fist-slide {
    height: 100vh !important;
        padding-top: 100px;
}
.main-background-image .second-slide {
       height: 100vh!important;
         padding: 200px 0;
}
.main-background-image .third-slide {
       height: 100vh!important;
         padding: 200px 0;
         
}
/* .main-background-image .third-slide::before {
      content: "";
    background-color: #0000006b;
    width: 100%;
    height: 100vh;
    z-index: -1;
    position: fixed;
} */
.fist-slide h3 {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 500;
    line-height: 115px;
    color: #FFFFFF;
    margin-top: 50px;
}
.main-background-image .fist-slide p {
    font-family: Futura;
    font-size: 25px;
    font-weight: 300;
    color: #fff;
    line-height: 41.37px;
        margin-left: 50px;
        margin-top: 100px;
    letter-spacing: 0.45em;
    text-align: left;
}
@keyframes blink {50% { color: transparent }}
.loader__dot { animation: 1s blink infinite }
.loader__dot:nth-child(2) { animation-delay: 250ms }
.loader__dot:nth-child(3) { animation-delay: 500ms }


.main-background-image .second-slide {
    height: 100vh !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.second-slide h3 {
    font-family: Raleway;
    font-size: 35px;
    font-weight: 400;
    line-height: 52.83px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 25px;
}
.second-slide p {
    font-family: Raleway;
    font-size: 17px;
    font-weight: 200;
    line-height: 23.48px;
    text-align: left;
    color: #fff;
    width: 590px;
    margin-bottom: 56px;
}
.second-slide h4 {
    font-family: Raleway;
    font-size: 34px;
    font-weight: 400;
    line-height: 46.96px;
    text-align: left;
    color: #fff;
}
.second-slide h4 a {
    color: #fff;
}
.main-background-image .third-slide {
    height: 100vh !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.third-slide h3 {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 35.22px;
    text-align: left;
    color: #fff;
}
.third-slide  .top-h {
    margin-top: 40px;
}
.third-form label.form-label {
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 29.35px;
    text-align: left;
    color: #fff;
    margin: 0;
}
.third-form input.form-control {
    border: 2px solid #ffffff47;
    background: transparent;
    color: #D6D6D6;
    border-radius: 0;
    width: 350px;
    height: 45px;
    font-size: 13px;
}
.third-form input.form-control::placeholder {
    font-size: 13px;

    color: #D6D6D6;

    
}
.third-form button {
    width: 186px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    background: radial-gradient(281.09% 281.09% at 50% 50%, #103D56 0%, #8BB9D6 49.52%, #4C8BB4 100%), linear-gradient(90deg, #8BB9D6 12.5%, #4C8BB4 87.5%), #002395;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45px;
    margin-top: 28px;
}
.third-form {
    margin-top: 70px;
}

.main-background-image .slick-dots {
    position: fixed !important;
    right: 0 !important;
    top: 0 !important;
    width: 200px;
    bottom: 0 !important;
    transform: rotate(90deg);
    display: flex !important;
    align-items: center;
     --animate-delay: 5s; 
    --animate-duration: 2000ms;
        --animation-name: fadeInUp;

}


.main-background-image .slick-dots li button:before {

    width: 21px;
    border-radius: 37px;
    border: 2px solid #fff;
    height: 21px;
    opacity: 1;
    
}
.main-background-image .slick-dots li {
    width: 21px;
    
   border-color: #fff;
    height: 21px;
}
.main-background-image .slick-dots .slick-active button:before {
        color: #fff;
    width: 21px;
    border-radius: 37px;
    height: 21px;
    opacity: 1;
    padding: 0px 0px 0px 0px;
    font-size: 15px;
}

.main-background-image .slick-arrow.slick-prev {
    position: fixed;
    top: 5px;
    left: 0;
    right: 0;
    z-index: 11;
    width: 60px;
    visibility: visible;
    opacity: 1;
    transition: 0.5s ease;
    margin: auto;
}
.main-background-image .slick-arrow.slick-next {
    position: fixed;
    bottom: 103px !important;
    left: 0;
    z-index: 11;
    top: unset !important;
    width: 60px;
    right: 0;
    margin: auto;
}
.main-background-image .slick-arrow.slick-prev.slick-disabled {
    visibility: hidden;
    opacity: 0;
}
.main-background-image .slick-arrow.slick-next.slick-disabled {
    display: none!important;
}
.main-background-image .slick-next:before {
content: "";
background-image: url("../images/slidedown.svg");
    width: 60px;
    display: block;
    height: 60px;
    background-repeat: no-repeat;
    border: 3px solid #fff;
    background-position: center;
    transition:  all .2s ease 0s;
    -webkit-transition: all 150ms cubic-bezier(1,0,0,1);
    transition: all 150ms cubic-bezier(1,0,0,1);
     opacity: 1;
    animation: fadeInUp; 
    webkit-transition:  all .2s ease 0s;
    transition: all .2s ease 0s;
    animation-duration: 1s;
 border-radius: 5px 5px 5px 5px;
}
.main-background-image .slick-next:after {
    top: -95px!important;
    opacity: 0!important;
    transform: 0.2s ease;
}
.main-background-image .slick-next:hover:after {
    top: -60px!important;
    opacity: 1!important;
}
.main-background-image .slick-next:hover:before  {
    top: 10px;
    background-position: 20px 73px;
}
.main-background-image .slick-next:after {
content: "";
background-image: url("../images/slidedown.svg");
    width: 60px;
    display: block;
    height: 60px;
    background-repeat: no-repeat;
    top: -112px;
    position: relative;
    webkit-transition: all .6s ease 0s;
    transition: all .6s ease 0s;
    background-position: center;
    transition: all .6s ease 0s;
    -webkit-transition: all 150ms cubic-bezier(1,0,0,1);
    transition: all 150ms cubic-bezier(1,0,0,1);
     opacity: 1;
    animation: fadeInUp; 
  animation-duration: 2s;
 
}
.overlay-open {
    position: absolute;
    z-index: -1;
    top: 0;
    opacity: 0;
    visibility: hidden;

    
    overflow: hidden;
    width: 100%;
    height: 100%;
    -webkit-transition: all .8s ease;
    transition: all .8s ease;
    pointer-events: none;
    background: rgba(0, 0, 0, .8);
}
.overlay-open.active {
    opacity: 1;
    visibility: visible;
}
/* .main-background-image .slick-next:after {
content: "";
background-image: url("../images/slidedown.svg");
width: 60px;
    display: block;
    height: 60px;
    transition: all .6s ease 0s;
    background-repeat: no-repeat;
    transition: all 150ms cubic-bezier(1,0,0,1);
    background-position: center;
    opacity: 1;
    position: absolute;
    top: -100px;
} */
.main-background-image .slick-prev:before {
content: "";
background-image: url("../images/slideup.svg");
    width: 60px;
    display: block;
    height: 60px;
    background-color: #fff;
    opacity: 1;
    background-repeat: no-repeat;
    border: 2px solid #fff;
    background-position: center;
    animation: fadeInUp; 
  animation-duration: 2s;
  border-radius: 0 0 5px 5px;
}
.main-background-image .slick-prev:after {
content: "";
background-image: url("../images/slideup.svg");
    width: 60px;
    display: block;
    height: 60px;
  
    opacity: 1;
    background-repeat: no-repeat;
   
    background-position: center;
    animation: fadeInUp; 
  animation-duration: 2s;
}

.main-background-image .slick-prev:after {
    top: -18px!important;
    opacity: 0!important;
    transition: 0.2s ease;
    position: relative;
    
}
.main-background-image .slick-prev:hover:after {
    top: -60px!important;
    opacity: 1!important;
    position: relative;
    
}
.main-background-image .slick-prev:hover:before  {
    top: 10px;
    background-position: 20px 73px;
    transform: 0.2s ease;
    
}




:root {
  --animate-duration: 2000ms;
 
}

.animate__fadeInUp {
    --animate-delay: 5s; 
    --animate-duration: 1000ms;
}
.page-loader {
    position: fixed;
    z-index: 99999;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #111;
}
.page-loader .spinner-container {    
    position: absolute;
    z-index: 200;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
.page-loader .spinner-container .css-spinner {

       display: block;
    width: 36px;
    height: 36px;
    -webkit-transition: all .4s cubic-bezier(.19,1,.22,1);
    transition: all .4s cubic-bezier(.19,1,.22,1);
    -webkit-animation: spinner .4s linear infinite;
    animation: spinner .4s linear infinite;
    opacity: 1;
    border: solid 2px transparent;
    border-top-color: #1fb8b2;
    border-left-color: #1fb8b2;
    border-radius: 100%;
}
@-webkit-keyframes spinner {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes spinner {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}
.page-loader.hide-this {
    bottom: 100%;
    -webkit-transition: all .8s cubic-bezier(.54,.086,0,.98) .2s;
    transition: all .8s cubic-bezier(.54,.086,0,.98) .2s;
} 

@keyframes fadeInDDown {
   0% {
      opacity: 0;
      transform: translateY(-20px);
   }
   100% {
      opacity: 1;
      transform: translateY(0);
   }
} 



@keyframes fadeInMiddle {
  from {
    opacity: 0;
    transform: translateY(20%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate__fadeInMiddle {
  animation-name: fadeInMiddle;
}


/* Define keyframes for the animations */
@keyframes slideInFromTop {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideInFromBottom {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Apply animations to specific elements with delay */
.fist-slide img {
    animation: slideInFromTop 1s ease-out;
    animation-delay: 0.8s; /* Delay before starting the image animation */
    width: 400px;
}

.fist-slide h3, .fist-slide p {
    animation: slideInFromBottom 1s ease-out;
    animation-delay: 0.8s; /* Delay before starting the text animations */
}

/* Optional: Add delay for individual text elements if needed */
.fist-slide p {
    animation-delay: 0.8s; /* Additional delay for paragraph */
}

.loader__dot {
    display: inline-block;
    animation: blink 1s infinite;
}

@keyframes blink {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
}



.profile-card .css-b62m3t-container {
    box-shadow: 0px 2px 20px 0px #00239526;
    border-radius: 7px;
    padding: 1px 1px;
}
.profile-card .css-b62m3t-container .css-13cymwt-control {
    border: none;
     box-shadow: none;
}
.profile-card .css-b62m3t-container .css-t3ipsp-control {
    border-color: transparent;
    box-shadow: none;
    
}
.profile-card .css-b62m3t-container .css-1dimb5e-singleValue {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
}
.profile-card .css-b62m3t-container div#react-select-3-placeholder {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
}
.profile-card .css-b62m3t-container .css-1jqq78o-placeholder {
     font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
}

.profile-card .css-b62m3t-container .css-1jqq78o-placeholder {
    grid-area: 1 / 1 / 2 / 3;
    color: #000;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
} 






.cmn-form-fields .css-b62m3t-container {
    box-shadow: 0px 2px 20px 0px #00239526;
    border-radius: 7px;
    padding: 1px 1px;
}
.cmn-form-fields .css-b62m3t-container .css-13cymwt-control {
    border: none;
     box-shadow: none;
}
.cmn-form-fields .css-b62m3t-container .css-t3ipsp-control {
    border-color: transparent;
    box-shadow: none;
    
}
.cmn-form-fields .css-b62m3t-container .css-1dimb5e-singleValue {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
}
.cmn-form-fields .css-b62m3t-container div#react-select-3-placeholder {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
}
.cmn-form-fields .css-b62m3t-container .css-1jqq78o-placeholder {
     font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
}

.cmn-form-fields .css-b62m3t-container .css-1jqq78o-placeholder {
    grid-area: 1 / 1 / 2 / 3;
    color: #000;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
} 





.form-inner .css-b62m3t-container {
    box-shadow: 0px 2px 20px 0px #00239526;
    border-radius: 7px;
    padding: 1px 1px;
}
.form-inner .css-b62m3t-container .css-13cymwt-control {
    border: none;
     box-shadow: none;
}
.form-inner .css-b62m3t-container .css-t3ipsp-control {
    border-color: transparent;
    box-shadow: none;
    
}
.form-inner .css-b62m3t-container .css-1dimb5e-singleValue {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
}
.form-inner .css-b62m3t-container div#react-select-3-placeholder {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
}
.form-inner .css-b62m3t-container .css-1jqq78o-placeholder {
     font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
}

.form-inner .css-b62m3t-container .css-1jqq78o-placeholder {
    grid-area: 1 / 1 / 2 / 3;
    color: #000;
    margin-left: 2px;
    margin-right: 2px;
    box-sizing: border-box;
} 

.third-form label.form-label span {
    font-family: Raleway;
    font-size: 12px;
    font-weight: 500;
    line-height: 23.48px;
    margin-left: 4px;
}
.image-upload-in {
    width: 200px;
    position: relative;
    height: 140px;
}
.image-upload-in input {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}
.image-upload-in .user-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.phone-select .react-select-set.css-b62m3t-container {
    width: 140px;
}
.phone-select .css-hlgwow {
    padding: 0px 5px ;
}